import Vue from "vue";
import { LMap, LTileLayer, LMarker } from "vue2-leaflet";

import "beautifymarker/leaflet-beautify-marker-icon";
import "leaflet/dist/leaflet.css";
import "beautifymarker/leaflet-beautify-marker-icon.css";

Vue.component("l-map", LMap);
Vue.component("l-tile-layer", LTileLayer);
Vue.component("l-marker", LMarker);
