<template>
  <b-navbar class="main-header bg-white border-0">
    <b-nav class="navbar-nav">
      <b-nav-item @click="toggle()">
        <b-icon icon="layout-sidebar-inset"></b-icon>
      </b-nav-item>
    </b-nav>
    <!-- Right navbar links -->
    <b-nav class="navbar-nav ml-auto" v-if="user">
      <b-nav-item-dropdown
        :text="user.userName"
        toggle-class="text-dark text-capitalize"
      >
        <b-dropdown-item @click="logout">Sign out</b-dropdown-item>
      </b-nav-item-dropdown>
      <b-img
        :src="user.userImageUrl"
        fluid
        width="40"
        height="40"
        rounded="circle"
        style="height: 40px"
      ></b-img>
    </b-nav>
  </b-navbar>
</template>

<script>
import pushMenu from "@/mixins/PushMenu";
export default {
  mixins: [pushMenu],
  computed: {
    user() {
      return this.$auth.user() || null;
    }
  },
  methods: {
    logout() {
      this.$store.dispatch("auth/logout");
    }
  }
};
</script>
