import Vue from "vue";
import { Md5 } from "ts-md5";

export default {
  namespaced: true,

  state() {
    return {
      ha1: null,
      username: null
    };
  },

  actions: {
    fetch(data) {
      return Vue.auth.fetch(data);
    },

    refresh(data) {
      return Vue.auth.refresh(data);
    },

    async login(ctx, data) {
      data = data || {};

      const ha1 = Md5.hashStr(
        `${data.body.email}:${process.env.VUE_APP_realm}:${data.body.password}`
      ).toString();

      ctx.commit("setHa1", ha1);
      ctx.commit("setUsername", data.body.email);

      return await Vue.auth.login({
        url: "site",
        method: "GET",
        staySignedIn: data.staySignedIn,
        fetchUser: false,
        // redirect: { name: "summary" }
      });
    },

    logout(ctx) {
      ctx.commit("setHa1", null);
      ctx.commit("setUsername", null);
      return Vue.auth.logout();
    }
  },

  getters: {
    user() {
      return Vue.auth.user();
    }
  },
  mutations: {
    setHa1(state, value) {
      state.ha1 = value;
    },
    setUsername(state, value) {
      state.username = value;
    }
  }
};
