import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    redirect: "/login"
  },
  {
    path: "/login",
    name: "login",
    meta: { auth: false, layout: "no-nav" },
    component: () => import("@/views/Login.vue")
  },
  {
    path: "/summary",
    name: "summary",
    meta: {
      auth: true,
      layout: "dashboard"
    },
    component: () => import("@/views/Summary.vue")
  },
  {
    path: "/global-gap",
    name: "global-gap",
    meta: {
      auth: true,
      layout: "dashboard"
    },
    component: () => import("@/views/Global-gap.vue")
  },
  {
    path: "/farming",
    name: "farming",
    meta: {
      auth: true,
      layout: "dashboard"
    },
    component: () => import("@/views/Farming.vue")
  },
  {
    path: "/farmer-details/:uniqueId",
    name: "farmer-details",
    meta: {
      auth: true,
      layout: "dashboard"
    },
    component: () => import("@/views/Farmer-details.vue")
  }
];

Vue.router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

export default Vue.router;
