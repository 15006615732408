import Vue from "vue";

import auth from "@websanova/vue-auth/dist/v2/vue-auth.common.js";
import driverHttpAxios from "@websanova/vue-auth/dist/drivers/http/axios.1.x.js";
import driverRouterVueRouter from "@websanova/vue-auth/dist/drivers/router/vue-router.2.x.js";
import store from "../store";

Vue.use(auth, {
  plugins: {
    http: Vue.axios,
    router: Vue.router
  },
  drivers: {
    http: driverHttpAxios,
    router: driverRouterVueRouter,
    auth: {
      request: (config, ha1) => {},
      response: function(res) {
        if (res.status === 200) {
          return store.state.auth.ha1;
        }
      }
    }
  },
  options: {
    notFoundRedirect: { name: "summary" },
    refreshData: {
      enabled: false,
      url: "site"
    },
    fetchData: {
      enabled: false,
      url: "site"
    },
    rolesKey: "userName",
    parseUserData: function(data) {
      if (data[0].status.code === 0) {
        return data[0].objectList[0];
      }
      return null;
    }
  }
});
