<template>
  <aside class="main-sidebar sidebar-light-green border-right">
    <!-- Brand Logo -->
    <a href="#" class="brand-link pl-4">
      <img src="@/assets/img/zireplus_logo.png" class="brand-image mx-auto" />
      <span class="brand-text font-weight-bold pl-2">
        ZIREPLUS
      </span>
    </a>

    <!-- Sidebar -->
    <div class="sidebar">
      <!-- Sidebar Menu -->
      <nav class="mt-2">
        <b-nav pills class="nav-sidebar flex-column nav-child-indent">
          <li
            class="nav-item"
            :class="item.path === $route.path ? 'menu-open' : ''"
            v-for="item in menu"
            :key="item.name"
          >
            <b-link
              class="nav-link"
              :to="item.path"
              exact-active-class="active"
            >
              <b-icon :icon="item.icon" class="nav-icon"></b-icon>
              <p>
                {{ item.title }}
                <b-icon
                  v-if="item.items"
                  :icon="
                    item.path === $route.path ? 'chevron-down' : 'chevron-left'
                  "
                  class="right"
                  font-scale="1"
                ></b-icon>
              </p>
            </b-link>
          </li>
        </b-nav>
      </nav>
      <!-- /.sidebar-menu -->
    </div>
    <!-- /.sidebar -->
  </aside>
</template>

<script>
export default {
  data: () => ({
    menu: [
      {
        title: "Summary",
        name: "summary",
        path: "/summary",
        icon: "collection"
      },
      {
        title: "Farming for the future",
        name: "farming",
        path: "/farming",
        icon: "binoculars"
      },
      // {
      //   title: "Global Gap",
      //   name: "global-gap",
      //   path: "/global-gap",
      //   icon: "globe2"
      // }
    ]
  })
};
</script>

<style></style>
