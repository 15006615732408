import Vue from "vue";

import axios from "axios";
import VueAxios from "vue-axios";

import store from "../store";
import { Md5 } from "ts-md5";

Vue.use(VueAxios, axios);

Vue.axios.defaults.baseURL = process.env.VUE_APP_API_URL;
Vue.axios.defaults.headers.common["Content-Type"] = "application/json";
Vue.axios.interceptors.request.use(
  config => {
    const username = store.state.auth.username;
    const ha1 = store.state.auth.ha1;
    const ha2 = Md5.hashStr(`${config.method.toUpperCase()}:${config.url}`);
    const nonce = new Date().getTime();
    const response = Md5.hashStr(`${ha1}:${nonce}:${ha2}`);
    const Authorization = `Digest username="${username}",realm="${process.env.VUE_APP_realm}", nonce="${nonce}",uri="${config.url}",response="${response}"`;

    config.headers = {
      "X-Concursive-Key": `key=${process.env.VUE_APP_key}`,
      Authorization,
    };

    config.data = { ...config.data };

    return config;
  },
  function(err) {
    return Promise.reject(err);
  },
  { synchronous: true }
);
